<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="page-container-card primary--border" outlined>
          <v-card-title class="title">
            Sliders
            <v-spacer></v-spacer>

            <add-button
              icon="crop_free"
              class="mr-3"
              v-if="form.items.data.length >= 1"
              @action="slider_preview = true"
              permission="slider-read"
            >
              Preview
            </add-button>

            <add-button
              v-if="form.items.data.length < 5"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              permission="slider-create"
            >
              Add New Slide
            </add-button>
          </v-card-title>

          <v-container>
            <alert-message
              v-if="form.items.data.length >= 5"
              type="success"
              title="Slider Image Upload Limit Reached"
              message="Upload MAX 5 Images. The Uploader images will appear on your school's website and mobile app's home screen."
            ></alert-message>

            <alert-message
              v-else
              title="Slider Image"
              message="Upload MAX 5 Images. The Uploader images will appear on your school's website and mobile app's home screen."
            ></alert-message>
          </v-container>

          <br />

          <v-data-table
            :headers="headers"
            hide-default-footer
            :loading="form.loading"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.title }}
                  <small
                    class="text-light"
                    style="display: block; color: #999"
                    >{{ item.description }}</small
                  >
                </td>
                <td class="text-xs-left">
                  <div
                    style="
                      border: 1px dotted #ccc;
                      display: inline-block;
                      margin: 5px 0;
                    "
                  >
                    <img height="50" :src="item.image" alt="" />
                  </div>
                </td>
                <td class="text-right">
                  <edit-button
                    permission="grade-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Slider Details</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 class="mb-0">
                    <v-text-field
                      outlined
                      dense
                      label="Slider Title*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.title"
                      name="name"
                      :error-messages="form.errors.get('title')"
                      :rules="[(v) => !!v || 'Slider Title Field is required']"
                    />
                  </v-flex>
                  <v-flex xs12 class="ma-0">
                    <v-textarea
                      rows="4"
                      outlined
                      dense
                      :error-messages="form.errors.get('description')"
                      v-model="form.description"
                      name="description"
                      label="Description"
                      hint="Short and sweet notice is always eye catching."
                    />
                  </v-flex>
                  <v-scroll-y-reverse-transition>
                    <!-- <div
                      v-if="imagePicker.image || form.data().image"
                      style="cursor:pointer;height: 266px;width: 100%;background-color: #ccc;border: 1px dashed #999"
                    > -->
                    <!-- <v-flex
                        xs12
                        text-center
                        class="pa-0"
                        v-if="imagePicker.image"
                        style="position: relative;"
                      >
                        <v-img
                          :src="imagePicker.image"
                          gradient="to top right, rgba(100,115,201,.11), rgba(25,32,72,.4)"
                        ></v-img>
                      </v-flex> -->
                    <!-- <v-flex
                        xs12
                        text-center
                        class="pa-0"
                        style="position: relative;"
                      >
                        <v-img
                          :src="form.data().image"
                          gradient="to top right, rgba(100,115,201,.11), rgba(25,32,72,.4)"
                        ></v-img>
                      </v-flex> -->
                    <!-- </div> -->
                  </v-scroll-y-reverse-transition>
                  <v-flex xs12 text-center>
                    <image-picker
                      v-model="form.image"
                      uiType="dragger"
                      label="Select Slider Image"
                      ref="picker"
                      :ratio="16 / 9"
                      @result="onPickerResult"
                    ></image-picker>
                  </v-flex>
                   <v-flex xs12>
                    <alert-message
                      title="Crop Alert"
                      :type="'warning'"
                      extra-small
                      message="All the slider images are cropped on 16:9 Ration in order to maintain a standard size on school's website and mobile app."
                    ></alert-message>
                  </v-flex>
                  <v-flex xs12>
                    <alert-message
                      title="Slider Image"
                      message="Slider will appear on school's mobile home screen and school's website"
                    ></alert-message>
                  </v-flex>
                 
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        @keydown.esc="slider_preview = false"
        v-model="slider_preview"
        persistent
        max-width="1000px"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Slider Preview</span>
            <v-spacer></v-spacer>
            <v-icon @click="slider_preview = false" style="color: white"
              >close</v-icon
            >
          </v-card-title>
          <v-card-text class="pa-1">
            <v-carousel>
              <v-carousel-item
                v-for="(item, i) in form.items.data"
                :key="i"
                :src="item.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

export default {
  data: () => ({
    valid: true,
    lazy: false,
    headers: [
      { text: "SN", align: "center", value: "id", width: 30, sortable: false },
      {
        text: "Title",
        align: "left",
        value: "name",
        sortable: false,
        width: 400,
      },
      { text: "Image", align: "left", value: "subject", sortable: false },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    form: new Form(
      {
        title: "",
        description: "",
        image: "",
      },
      "/api/slider"
    ),
    search: null,
    pagination: {},
    imagePicker: {
      name: null,
      image: null,
      src: null,
      cropped: {},
    },
    slider_preview: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.get();
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map((key) => {
            if (typeof json[key] === "object") {
              let vals = [...json[key]];
              if (vals.length)
                return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(vals.join(","))
                );
              else return null;
            }
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&")
      );
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.store();
      }
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    store() {
      if (
        this.imagePicker &&
        Object.keys(this.imagePicker.cropped).length > 0
      ) {
        this.form.image = this.imagePicker.cropped;
      } else {
        delete this.form.image;
      }
      if (this.form.data.length < 5)
        this.form.store().then(() => {
          this.get();
        });
    },

    onPickerResult(data) {
      this.imagePicker.cropped = data.cropped;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.album_style_mis {
  padding: 0 20px;
  width: 50% !important;

  h3 {
    font-size: 17px !important;
    border-bottom: 1px solid white;
  }

  p {
    font-size: 15px;
  }
}

.container.grid-list-md .layout .flex {
  margin-bottom: 40px;
}

.v-btn--bottom.v-btn--absolute.v-btn--small.edit {
  right: 60px;
}
</style>
